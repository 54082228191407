<template>
  <div
    class="speical-small-card"
    :style="{ height: (ClientWidth - 24) * 0.35 + 16 + 'px' }"
    @click="handleOpenURL(item.id)"
  >
    <img
      :style="{
        width: (ClientWidth - 24) * 0.35 + 'px',
        height: (ClientWidth - 24) * 0.35 + 'px',
      }"
      :src="item.cover"
      alt=""
      class="speical-small-card-icon"
    />
    <div
      class="speical-small-card-content"
      :style="{ height: (ClientWidth - 24) * 0.35 + 'px' }"
    >
      <div class="speical-small-card-content-title">{{ item.title }}</div>
      <div class="speical-small-card-content-price" style="margin:10px 0 4px 0;">
        <div class="speical-small-card-content-price">
          <span class="speical-small-card-content-price-tag">¥</span>
          <span class="speical-small-card-content-price-price">{{
            item.price
          }}</span>
          <span class="speical-small-card-content-price-word">折扣价</span>
        </div>
        <div
          style="
            height: 10px;
            opacity: 1;
            border-left: 1px solid #cccccc;
            margin: 0 8px;
          "
        ></div>
        <div class="speical-small-card-content-price-word">
          <span style="text-decoration: line-through; color: #ccc"
            >¥{{ item.official_price }}</span
          >
          <span style="color: #cccccc; margin-left: 4px">商城价</span>
        </div>
      </div>
      <div class="daily-discount-redcard-content" :style="progressColor">
        <div
          :style="{
            width: ClientWidth<375? (ClientWidth ) * 0.39  + 'px':(ClientWidth -24 ) * 0.26  + 'px'
          }"
          class="posR"
        >
          <div :style="progressPresent" class="progressPresentStyle">

          </div>
          <div :style="progressWordBg" class="daily-discount-redcard-content-box">

          </div>
          <div :style="progressWordColor" class="daily-discount-redcard-content-box-progressWord">
            {{ progress }}
          </div>
        </div>
        <div class="daily-discount-redcard-content-right">
          <div
            class="daily-discount-redcard-content-right-persent"
            :style="bgColor"
          >
            {{ present }}%
          </div>
          <div class="daily-discount-redcard-content-right-tobuy">
            <template v-if="item.repertory&&!item.gold_unique">
              <div :style="buyColor">抢购</div>
              <img
                src="https://cdn.vgn.cn/static/newnewnew/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%402x%20(3).png"
                alt=""
              />
            </template>
            <template v-else-if="!item.repertory">
              <div :style="buyColor">售罄</div>
            </template>
            <template v-else>
              <div :style="buyColor">抢购</div>
              <img
                src="https://cdn.vgn.cn/static/newnewnew/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%402x%20(2).png"
                alt=""
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InteractionLib from "@/utils/interaction.lib";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class SpecialSmallCard extends Vue {
  @Prop() item;
  get ClientWidth() {
    const { clientWidth } = document.body;
    if (clientWidth < 375) {
      return 310;
    }
    return clientWidth;
  }
  get present() {
    return (
      Math.floor(-(1-(this.item.price / this.item.bazaar_price))*100 )
    );
  }
  get progress() {
    if(!this.item.repertory)return '已抢光'
    if(this.item.repertory == this.item.inventory) return `已抢0%`;
    return `已抢${100-Math.floor((this.item.repertory / this.item.inventory) * 100) }%`;
  }
  get progressColor() {
    const {clientWidth} = document.body
    if (!this.item.repertory) {
      return `background:#EBEBEB;width:${clientWidth*0.52}px;`;
    }
    if (this.item.gold_unique) {
      return `background: linear-gradient(90deg, #333333 0%, #020202 100%);width:${clientWidth*0.52}px`;
    }

    return  `background: linear-gradient(315deg, #fa5e41 0%, #f43041 100%);width:${clientWidth*0.52}px`
  }
  get buyColor(){
    if (!this.item.repertory) {
      return `color:#969696`;
    }
    if (this.item.gold_unique) {
      return `color: #FFCB66`;
    }
    return `color:#fff`;
  }
    handleOpenURL(id){
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/sp-store/${id}`
      );
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${id}` },
    });
  }
  get bgColor() {
    if (!this.item.repertory) {
      return `background: #BBBBBB;color:#FFFFFF`;
    }
    if (this.item.gold_unique) {
      return `background: #434242;color: #FFE9AD`;
    }
    return `background: #FF7878`;
  }
  get progressWordColor() {
     if (!this.item.repertory) {
      return `color: white;`;
    }
    if (this.item.gold_unique) {
      return `color: #9A5300;`;
    }

    return `color: #D20000;`;
  }

  get progressWordBg() {
    if (!this.item.repertory) {
      return `background: #CCCCCC;`;
    }
    if (this.item.gold_unique) {
      return `background: #8A7F62;`;
    }

    return `background: #FFE9E6;`;
  }
  get progressPresent(){
    if(this.item.repertory == this.item.inventory) return
    const progress =100- Math.ceil((this.item.repertory / this.item.inventory) * 1000) / 10
    if (!this.item.repertory) {
      return `background: #BBBBBB;width:${progress}%;height:30px;border-radius:2px`;
    }
    if (this.item.gold_unique) {
      return `background: linear-gradient(90deg, #FFEBB2 0%, #FFCB66 100%);width:${progress}%;height:30px;border-radius:2px`;
    }

    return `background: #FFC5BA;width:${progress}%;height:30px;border-radius:2px`;
  }
}
</script>

<style lang="scss" scoped>
.posR{
  position: relative;
}
.daily-discount-redcard-content-box-progressWord{
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-70%,-50%);
  font-size: 9px;
  font-weight: 800;
  white-space: nowrap;
}

.speical-small-card {
  box-shadow: 0px 1px 9px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  background: white;
  margin-top: 10px;
  display: flex;
  align-items: center;
  .speical-small-card-icon {
    border-radius: 4px;
    margin-left: 8px;
    object-fit: cover;
  }
  .speical-small-card-content {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    justify-content: space-between;
    .speical-small-card-content-title {
      font-size: 16px;
      font-weight: 800;
      color: #212832;
      text-overflow: ellipsis;
      height: 46px;
      display: -webkit-box;
      overflow: hidden;
      position: relative;
      z-index: 9;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .speical-small-card-content-price {
      display: flex;
      align-items: center;
      .speical-small-card-content-price-tag {
        font-size: 12px;
        font-weight: 800;
        color: #f74442;
      }
      .speical-small-card-content-price-price {
        font-weight: 800;
        font-size: 18px;
        color: #f74442;
        margin-top: -3px;
      }
      .speical-small-card-content-price-word {
        font-size: 10px;
        font-weight: 700;
        color: #f74442;
      }
    }
  }
}
.daily-discount-redcard-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  border-radius: 4px;
  .daily-discount-redcard-content-box {
    height: 30px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
  }
  .daily-discount-redcard-content-right {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    .daily-discount-redcard-content-right-persent {
      background: #ff7878;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 800;
      border-radius: 4px;
      font-size: 10px;
      padding: 2px;
      margin-left: 4px;
    }
    .daily-discount-redcard-content-right-tobuy {
      color: white;
      margin-left: 5px;
      font-size: 12px;
      white-space: nowrap;
      font-weight: 800;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}
.progressPresentStyle{
  position: absolute;
}
</style>
