<template>
  <div>
    <template v-if="State">
      <div class="daily-discount" style="z-index:1" >
    <div class="daily-discount-top-bg">
      <div class="daily-discount-top-title">
        <img
          src="https://cdn.vgn.cn/static/newnewnew/20220505-102544.png"
          alt=""
        />
        <div class="daily-discount-top-title-size">每天两款超低价大作</div>
      </div>
      <div class="daily-discount-top-must"></div>
      <SwiperCard />
    </div>
    <list
    style="margin-top: 86px"
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="loadData"
    :immediate-check="false"
  >
   <div
        style="margin: 0px 12px 10px 12px"
        v-for="(item, index) in SpecialSaleData"
        :key="index"
      >
        <SpeacilSmallCard :item="item"/>
      </div>
  </list>
      </div>
    </template>
    <img v-else src="https://cdn.vgn.cn/static/downloadpage/Slice%2011.png" alt="">
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import SwiperCard from "@/views/daily-discount/components/SwiperCard.vue";
import SpeacilSmallCard from "@/views/daily-discount/components/SpecialSmallCard.vue";
import CapStoreApi from "@/api/cap-store.api"
import { List } from "vant";


@Component({
  components: {
    SpeacilSmallCard,
    SwiperCard,
    List
  },
})
export default class DailyDiscount extends Vue {
  SpecialSaleData = []
  loading = false;
  finished = false;
  page = 0;
  page_size = 10;
  get State(){
    console.log(this.SpecialSaleData);
    return this.SpecialSaleData.length != 0
  }
  async loadData(){
    this.page++;
    const { page, page_size, SpecialSaleData } = this;
    const rows = await CapStoreApi.getSpecialSale(page, page_size).catch(
      () => []
    );
    this.loading = false;

    if (!rows.length || rows.length < this.page_size) this.finished = true;
    this.SpecialSaleData = page > 1 ? [...SpecialSaleData, ...rows] : rows;
  }
  created(){
    this.loadData()
  }

}
</script>

<style lang="scss" scoped>
.daily-discount-top-bg {
  background: url("https://cdn.vgn.cn/static/newnewnew/Slice%202%402x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 291px;
  width: 100%;
  .daily-discount-top-title {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 50px;
    img {
      height: 33px;
    }
    .daily-discount-top-title-size {
      opacity: 0.6;
      font-size: 14px;
      color: #fff;
      font-weight: 800;
    }
  }
  .daily-discount-top-must {
    background: url("https://cdn.vgn.cn/static/newnewnew/Group%2023%402x.png");
    width: 91px;
    height: 20px;
    background-size: 100% 100%;
    margin-left: 24px;
    margin-top: 16px;
  }
}
</style>
